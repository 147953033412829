import { ChangeDetectorRef, Injectable } from "@angular/core";
import { UIBehavior } from "src/app/ui/ui-behavior";
import { environment } from "src/environments/environment";
import { ContextService } from "../application/context.service";
import { SecurityService } from "./security.service";
import * as FW from "../../framework/core";

@Injectable({
  providedIn: "root",
})
export class ClientAdditionInformationService {
  // tslint:disable-next-line: variable-name
  private _scopeKey: string = null;
  private _scopePortalDomain: string = null;

  constructor(
    private context: ContextService,
    private securityService: SecurityService
  ) { }

  public loadScope(fn: any): void {
    let host: string;

    this._scopePortalDomain = this.context.authService.scopePortalDomain;

    if (environment.production) {
      this._scopeKey = this.context.authService.scopeKey;
    } else {
      if (environment.isHomolog) {
        this._scopeKey = this.context.navigation.getQueryParameter("scope");
      } else {
        // host = environment.testEnvironment;
        // this._scopeKey = host.replace(".personalichannel.com", "");
        this._scopeKey = this.context.authService.scopeKey;
      }
    }

    if (!FW.isNullOrEmpty(this.context.authService.currentScope) && FW.isNullOrEmpty(this._scopeKey))
      this._scopeKey = this.context.authService.currentScope.key;

    // this._scopeKey = 'validdemonstracao';
    if (!FW.isNullOrEmpty(this._scopeKey)) {
      this.securityService.getScopeByKey(this._scopeKey).subscribe((scope) => {
        this.context.authService.currentScope = scope;
        document.body.style.setProperty('--theme-primary', scope.baseColor);
        fn();
        UIBehavior.changeTheme(scope.theme);
        this.ConfigureTitleAndFavIcon();
      }, () => {
      });
    }

    if (!this._scopeKey && !FW.isNullOrEmpty(this._scopePortalDomain))
      this.securityService.getScopeByPortalDomain(this._scopePortalDomain).subscribe((scope) => {
        this.context.authService.currentScope = scope;
        this._scopeKey = scope.key;
        document.body.style.setProperty('--theme-primary', scope.baseColor);
        fn();
        UIBehavior.changeTheme(scope.theme);
        this.ConfigureTitleAndFavIcon();
      });
  }

  public ConfigureTitleAndFavIcon() {
    if (!FW.isNullOrEmpty(this.context.authService.currentScope)) {
      let title: any = document.getElementsByTagName('title');
      let appIcon: any = document.getElementsByClassName('appIcon');

      if (this.context.authService.currentScope.key == 'hedge') {
        if (!FW.isNullOrEmpty(title) && title.length > 0) {
          for (let index = 0; index < title.length; index++) {
            const element = title[index];
            element.text = 'Portal Hedge';
          }
        }

        if (!FW.isNullOrEmpty(appIcon) && appIcon.length > 0) {
          for (let index = 0; index < appIcon.length; index++) {
            const element = appIcon[index];
            element.setAttribute('href', 'https://www.hedgeinvest.com.br/assets/favicon.ico')
          }
        }
      }
    }
  }
}
